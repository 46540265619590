html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  font-family: 'Avenir LT W01_85 Heavy1475544';
  font-size: 18px;
  height: 100%;
  line-height: 1.4;

  -webkit-font-smoothing: antialiased;
}

body {
  height: 100%;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

button {
  cursor: pointer;
}

.Container {
  margin: 0 auto;
  max-width: 788px;
}

.Grid:before,
.Grid:after {
  content: " ";
  display: table;
}

.Grid:after {
  clear: both;
}

.Grid-unit {
  float: left;
  width: 100%;
}

.Grid--gutter {
  margin-left: -20px;
}

.Grid--gutter > .Grid-unit {
  padding-left: 20px;
}

.u-size1of2,
.u-size2of4 {
  width: 50%;
}

.u-size1of3 {
  width: 33.333%;
}

.u-size2of3 {
  width: 66.666%;
}

.u-size1of4 {
  width: 25%;
}

.u-size3of4 {
  width: 75%;
}

@media only screen and (min-width: 768px) {

  .u-md-size1of3 {
    width: 33.333%;
  }

}

.u-textCenter {
  text-align: center;
}

.Form {
  margin-bottom: 20px;
}

.FormInput {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.FormInput-text {
  padding: 10px;
  width: 100%;
}

h1, h2, h3, h4, p, ul, ol,
.h1, .h2, .h3, .h4 {
  margin-top: 0;
}

.Button {
  background: #4680FB;
  border: 0;
  color: #ffffff;
  font-size: 18px;
  padding: 10px 20px;
}

.ButtonSecondary {
  background: none;
  border: 0;
  color: #999999;
}

.ButtonGroupStack-item {
  margin-bottom: 50px;
}

.Canvas {
  padding-top: 2rem;
  /* background: #f2f2f2; */
  /* border: 1px solid #000000; */
  /* left: 256px;
  position: absolute;
  top: 76px;
  z-index: 3; */
}

.Canvas-buttonContainer {
  /* bottom: -60px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 5; */
}

.Canvas-button {
  float: right;
}

.CanvasControls {
  background: #ffffff;
  bottom: 0;
  left: 0;
  padding: 0.5rem;
  position: absolute;
  right: 0;
  width: 100%;
}

.CanvasControlsWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 544px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.Canvas-undoButton {
  background: transparent url(../../img/undo.svg) no-repeat 0 0;
  background-size: cover;
  border: 0;
  display: block;
  float: left;
  height: 45px;
  padding: 0;
  width: 45px;
}

.Canvas-undoButton:active {
  background: transparent url(../../img/undo-active.svg) no-repeat 0 0;
  background-size: cover;
}

.canvas-container {
  background: #fff;
  margin: 0 auto;
}

.Map {
  background-color: #ffffff;
  height: 100%;
  z-index: 1;
}

.leaflet-tile-container img {
  background: #fff;
}

.DrawContainer {
  background: #f2f2f2;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.TileWrapper {
  height: 100%;
  left: 50%;
  margin-left: -384px;
  position: absolute;
  width: 2000px;
  z-index: 2;
}

.TileWrapperBg {
  background: #000000;
  bottom: 0;
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.Tile {
  position: absolute;
  width: 256px;
  z-index: 1;
  /*top: -400px; background: pink*/
}

.DrawImage--canvas {
  z-index: 3;
}


.Loading {
  background: #000000;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.Loading-img {
  left: 50%;
  margin-left: -121px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}

.LoadingTile {
  background: #ffffff;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.LoadingTile-text {
  font-size: 14px;
  margin: 0 auto;
  max-width: 210px;
  padding-top: 20px;
  text-align: center;
}

.LoadingTile-img {
  left: 50%;
  margin-left: -121px;
  margin-top: -90px;
  position: absolute;
  top: 50%;
}

@media only screen and (min-width: 768px) {

  .LoadingTile-text {
    max-width: none;
  }

}

.Spinner {
  margin: 0 auto 50px;
  padding-top: 100px;
  text-align: center;
  width: 70px;
}

.Spinner-item {
  background-color: #333;
  border-radius: 100%;
  display: inline-block;
  height: 18px;
  width: 18px;

  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.Spinner-item--bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.Spinner-item--bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.DrawButton {
  background: #f2f2f2;
  display: block;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.DrawButton-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

.DrawButton-content {
  left: 0;
  margin-top: -18px;
  position: absolute;
  top: 50%;
  width: 100%;
}

.Search {
  margin-bottom: 20px;
}

.SingleInput {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.SingleInput-text {
  border: 0;
  font-size: 50px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.EmailInput {
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.EmailInput-text {
  background: #f2f2f2;
  border: 0;
  font-size: 20px;
  padding: 10px;
  text-align: left;
  width: 100%;
}

/* .EmailInput-checkbox {
  background: #f2f2f2;
  border: 0;
  font-size: 20px;
  padding: 10px;
  text-align: left;
  width: 100%;
} */

/*.SingleInput-text::-webkit-input-placeholder {
  text-align: center;
}

.SingleInput-text:-moz-placeholder {
  text-align: center;
}

.SingleInput-text::-moz-placeholder {
  text-align: center;
}

.SingleInput-text:-ms-input-placeholder {
  text-align: center;
}

.SingleInput-text::-ms-input-placeholder {
  text-align: center;
}*/

@media only screen and (min-width: 600px) {

  .SingleInput {
    max-width: 560px;
  }

  .SingleInput-text {
    font-size: 60px;
  }

  .EmailInput {
    max-width: 500px;
  }

}

@media only screen and (min-width: 768px) {

  .SingleInput {
    max-width: 700px;
  }

  .SingleInput-text {
    font-size: 100px;
  }

}

@media only screen and (min-width: 900px) {

  .SingleInput {
    max-width: 800px;
  }

  .SingleInput-text {
    font-size: 150px;
  }

}

/*.SearchButton {
  height: 48px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 48px;
  z-index: 2;
}*/

.SearchClose {
  position: absolute;
  top: 16px;
  right: 18px;
}

.Cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.SiteNav {
  left: 10px;
  position: absolute;
  top: 10px;
  z-index: 2;
}

.SiteNav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.SiteNav-item {
  float: left;
  margin-right: 6px;
}

.SiteNav-link {
  display: block;
}

.Empty {
  display: block;
  overflow: hidden;
  position: relative;
}

.Empty-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

.Empty-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Slot {
  background: #ffffff;
  display: block;
  opacity: 0.2;
  overflow: hidden;
  position: relative;
}

.Slot-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

.Slot-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.Table {
  display: table;
  height: 100%;
  width: 100%;
}

.Table-cell {
  display: table-cell;
  width: 100%;
}

.Table-cell--middle {
  vertical-align: middle;
}

.leaflet-control-zoom {
  box-shadow: none;
}

.leaflet-control-zoom .leaflet-control-zoom-in,
.leaflet-control-zoom .leaflet-control-zoom-out {
  background: #000000;
  color: #ffffff;
}

.leaflet-control-zoom .leaflet-control-zoom-in,
.leaflet-control-zoom .leaflet-control-zoom-out,
.leaflet-control-zoom .leaflet-control-zoom-in:hover,
.leaflet-control-zoom .leaflet-control-zoom-out:hover {
  font-size: 28px;
  height: 41px;
  line-height: 41px;
  width: 31px;
}

.no-scroll {
  overflow: hidden;
}

.WindowOverlay {
  background-color: rgba(204, 204, 204, 0.90);
  bottom: 0;
  display: none;
  height: 100%;
  left: 0;
  overflow-y: auto;
  padding-left: 10px;
  padding-right: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}

.WindowOverlay.is-open {
  display: block;
}

.Window {
  background: #ffffff;
  margin: 20px auto;
  max-width: 600px;
  padding: 60px 30px 30px;
  position: relative;
  z-index: 10;
}

.Window-close {
  background: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 15;
}

.Window-closeIcon {
  width: 30px;
  display: block;
  height: 30px;
  position: relative;
}

.Window-closeIcon::before,
.Window-closeIcon::after {
  background-color: #000000;
  height: 3px;

  /*transition: 0.3s;*/
  width: 100%;
}

.Window-closeIcon::before,
.Window-closeIcon::after {
  content: '';
  position: absolute;
}

.Window-closeIcon::before {
  left: 0;
  top: 50%;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.Window-closeIcon::after {
  left: 0;
  top: 50%;
  -webkit-transform: rotate(-45deg);
}

.Window-content {
  text-align: center;
}

.Window-title {
  font-size: 25px;
  font-weight: normal;
  line-height: 1;
  margin: 0 0 20px;
  word-wrap: break-word;
}

.Window-drawing {
  margin: 0 auto 25px;
  max-width: 300px;
}

@media only screen and (min-width: 768px) {

  .Window {
    padding: 100px 60px 60px;
  }

  .Window-title {
    font-size: 45px;
    margin: 0 0 40px;
  }

  .Window-drawing {
    margin: 0 auto 50px;
    max-width: none;
  }

}

.Share {
  display: inline-block;
}

.Share + .Share {
  margin-left: 20px;
}

.WinTitle {
  margin-bottom: 50px;
  padding-top: 50px;
}

.WinContent {
  margin: 0 auto 50px;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.u-responsiveImage {
  max-width: 100%;
}

.responsive-image {
  max-width: 100%;
}

.u-block {
  display: block;
}

.u-hidden {
  display: none !important;
  visibility: hidden;
}

.u-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.u-visuallyhidden.focusable:active,
.u-visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.u-invisible {
  visibility: hidden;
}

.u-cf:before,
.u-cf:after {
  content: " ";
  display: table;
}

.u-cf:after {
  clear: both;
}
